<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">

		<SiteHeader />
			
		<div class="section mainbanner-section relative pt-48 pb-16 lg:py-0 lg:h-0 min-h-screen bg-[#101010] overflow-hidden lg:pt-12">
			<div class="flex items-center h-full overflow-hidden">
				<div class="container text-white lg:pt-12 ">
					<div class="grid items-center justify-between gap-8 lg:flex">
						<div class="flex-1 order-1 lg:pb-16 lg:order-2 lg:mt-0">
							<img src="@/assets/images/mainbanner-img01.png" alt="Aspen Valves &amp; Fittings products" width="1205" height="494" data-aos="fade-right" data-aos-duration="1000">
						</div>
						<!-- <img src="@/assets/images/mainbanner-img02.png" alt="Aspen Valves &amp; Fittings products" width="666" height="375"> -->
						<div class="flex-none order-2 text-3xl text-center lg:text-5xl xl:text-6xl lg:leading-normal lg:order-1 lg:text-left">
							<div data-aos="fade-left" data-aos-duration="1000" class="">Your One Stop</div>
							<div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" class="font-bold font-rubik lg:text-7xl xl:text-8xl">Industrial</div>
							<div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" class="font-bold font-rubik lg:text-7xl xl:text-8xl">Supplier</div>
							<div class="mt-4" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="300">
								<a class="inline-block px-8 py-4 text-lg uppercase rounded bg-siteRed" href="#contactus" v-smooth-scroll="{offset: -56}">
									Inquire Now
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="absolute bottom-0 flex justify-center w-full duration-200 pointer-events-none" :class="isTop ? '':'opacity-0'">
				<a href="#products" v-smooth-scroll="{offset: -56}" class="flex flex-col items-center justify-center px-4 py-2 text-white duration-200 hover:bg-black rounded-t-xl" :class="isTop ? 'pointer-events-auto':''">
					<div class="lg:hidden">&#9650;</div>
					<div class="text-xs italic opacity-50 lg:hidden">swipe</div>
					<div class="hidden lg:block">&#9660;</div>
					<div class="hidden text-xs italic opacity-50 lg:block">scroll</div>
				</a>
			</div>
		</div>

		<div id="products" class="section products-section bg-[#FFFFFFE8]">
			<div class="container py-8 space-y-8 lg:py-16 lg:space-y-12">
				<div class="">
					<img class="mx-auto" src="@/assets/images/mainbanner-img02.png" alt="" width="666" height="375">
				</div>
				<div class="text-2xl font-bold tracking-widest text-center uppercase lg:text-4xl font-rubik text-siteBlue-3">
					Products & Services
				</div>
				<carousel
					class="pl-[1px]"
					:autoHeight="false"
					:autoplay="true"
					:autoplayTimeout="5000"
					:dots="true"
					:loop="true"
					:nav="false"
					:rewind="true"
					:margin="32"
					:responsive="{
						0: {items: 1},
						640: {items: 2},
						1024: {items: 3},
					}"
				>
					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_01.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_02.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_03.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_04.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_05.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_06.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_07.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_08.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_09.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_10.jpg" alt="" width="353" height="250">
					</div>

					<div class="">
						<img class="" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/product_slider_11.jpg" alt="" width="353" height="250">
					</div>

				</carousel>

				<div class="space-y-16 !mt-12 lg:!mt-24">
					<div class="px-4 pb-4 space-y-4 bg-white border-2 border-t-0 border-gray-300 font-oswald">
						<div class="pt-6 mx-[calc(-1rem-2px)] text-3xl font-bold leading-none tracking-widest uppercase lg:leading-none lg:text-5xl text-siteRed">
							<div class="flex items-center gap-4 -mt-10 lg:-mt-12">
								<div class="w-full py-4 border-r-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
								<div class="flex-none text-shadow">Fire Protection</div>
								<div class="w-full py-4 border-l-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
							</div>
						</div>
						<ul class="pl-10 space-y-2 text-xl font-bold lg:space-y-4 lg:text-3xl bullet-special product-columns">
							<li class="pr-4">Gate Valve</li>
							<li class="pr-4">Check Valve</li>
							<li class="pr-4">Butterfly w/ Tamper Switch</li>
							<li class="pr-4">Alarm Check Valve</li>
							<li class="pr-4">Flow Meter for Fire Pump</li>
							<li class="pr-4">Foot Valve</li>
							<li class="pr-4">Fire Hose Cabinet Set</li>
							<li class="pr-4">Sprinkler (Pendent, Sidewall, Upright)</li>
						</ul>
					</div>

					<div class="px-4 pb-4 space-y-4 bg-white border-2 border-t-0 border-gray-300 font-oswald">
						<div class="pt-6 mx-[calc(-1rem-2px)] text-3xl font-bold leading-none tracking-widest uppercase lg:leading-none lg:text-5xl text-siteRed">
							<div class="flex items-center gap-4 -mt-10 lg:-mt-12">
								<div class="w-full py-4 border-r-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
								<div class="flex-none text-shadow">Fittings</div>
								<div class="w-full py-4 border-l-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
							</div>
						</div>
						<ul class="pl-10 space-y-2 text-xl font-bold lg:space-y-4 lg:text-3xl bullet-special product-columns">
							<li class="pr-4">Stainless / Sanitary</li>
							<li class="pr-4">Black Iron / Galvanized Iron</li>
							<li class="pr-4">Slip-on Flange</li>
							<li class="pr-4">Elbow</li>
							<li class="pr-4">Tee</li>
							<li class="pr-4">Coupling</li>
							<li class="pr-4">End Cap</li>
							<li class="pr-4">Concentric Reducer</li>
						</ul>
					</div>

					<div class="px-4 pb-4 space-y-4 bg-white border-2 border-t-0 border-gray-300 font-oswald">
						<div class="pt-6 mx-[calc(-1rem-2px)] text-3xl font-bold leading-none tracking-widest uppercase lg:leading-none lg:text-5xl text-siteRed">
							<div class="flex items-center gap-4 -mt-10 lg:-mt-12">
								<div class="w-full py-4 border-r-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
								<div class="flex-none text-shadow">Valves</div>
								<div class="w-full py-4 border-l-2 border-gray-300 lg:py-6">
									<hr class="border-t-2 border-gray-300">
								</div>
							</div>
						</div>
						<ul class="pl-10 space-y-2 text-xl font-bold lg:space-y-4 lg:text-3xl bullet-special product-columns">
							<li class="pr-4">Cast Iron Valves</li>
							<li class="pr-4">Cast Steel Valves</li>
							<li class="pr-4">Marine Valves</li>
							<li class="pr-4">Pressure Relief Valves</li>
							<li class="pr-4">Brass Valves</li>
							<li class="pr-4">Resilient Gate Valves</li>
							<li class="pr-4">Lavatory Angle Valve</li>
						</ul>
					</div>
				</div>

			</div>
		</div>

		<div class="section brands-section bg-[#303842F0]">
			<div class="container py-8 space-y-4 text-white lg:pt-16 lg:pb-32 lg:space-y-12">
				<div class="text-2xl font-bold tracking-widest text-center uppercase lg:text-4xl font-rubik">
					Our Brands
				</div>
				<carousel
					class="pl-[1px]"
					:autoHeight="false"
					:autoplay="true"
					:autoplayTimeout="5000"
					:dots="true"
					:loop="true"
					:nav="false"
					:rewind="true"
					:margin="32"
					:responsive="{
						0: {items: 2, margin: 16},
						640: {items: 3, margin: 16},
						1024: {items: 4, margin: 32},
					}"
				>
					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-01.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Dayo Valves</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-02.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Fairfortune Valves</div>
					</div>

					<!-- <div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-04.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Fire Guard</div>
					</div> -->

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-05.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Gem</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-06.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">GVI</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-07.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Mech Fittings</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-08.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Protector</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-09.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Schmidt</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-10.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Singer</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-03.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Superflow</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-11.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Tyco</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-12.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Volon Hyflo</div>
					</div>

					<div class="grid items-center justify-center gap-y-4">
						<img class="!w-auto rounded-lg" style="outline: 1px solid #DDD; outline-offset: -1px;" src="@/assets/images/brands-13.jpg" alt="" width="813" height="450">
						<div class="text-center text-white font-bold">Jianzhi</div>
					</div>

				</carousel>
			</div>
		</div>

		<div class="bg-[#FFFFFFE8] section policy-section">
			<div class="container py-8 lg:pt-0 lg:pb-16">
				<div class="grid gap-8 lg:-mt-16 lg:grid-cols-3 lg:px-8 xl:px-16 xl:gap-16">

					<div class="py-8 space-y-4 text-center text-white rounded-md shadow-lg bg-siteBlue md:pb-8 lg:pt-12 lg:pb-12">
						<img src="@/assets/images/policy-img01.png" alt="" class="mx-auto" width="145" height="145">
						<div class="font-bold leading-tight font-rubik lg:text-2xl lg:leading-tight">
							Free <span class="lg:block">Delivery</span>
						</div>
						<hr class="w-16 mx-auto border-t-8 border-siteRed">
						<div class="px-4 pt-2 leading-tight lg:px-10 xl:px-14 text-md lg:text-lg lg:leading-tight">
							Our company offers free delivery services within Metro Manila. (with Terms and Conditions applied)
						</div>
					</div>

					<div class="py-8 space-y-4 text-center text-white rounded-md shadow-lg bg-siteBlue md:pb-8 lg:pt-12 lg:pb-12">
						<img src="@/assets/images/policy-img02.png" alt="" class="mx-auto" width="145" height="145">
						<div class="font-bold leading-tight font-rubik lg:text-2xl lg:leading-tight">
							Wide Range <span class="lg:block">of Products</span>
						</div>
						<hr class="w-16 mx-auto border-t-8 border-siteRed">
						<div class="px-4 pt-2 leading-tight lg:px-10 xl:px-14 text-md lg:text-lg lg:leading-tight">
							Caters products both to end-users and business to business.
						</div>
					</div>

					<div class="py-8 space-y-4 text-center text-white rounded-md shadow-lg bg-siteBlue md:pb-8 lg:pt-12 lg:pb-12">
						<img src="@/assets/images/policy-img03.png" alt="" class="mx-auto" width="145" height="145">
						<div class="font-bold leading-tight font-rubik lg:text-2xl lg:leading-tight">
							After Sales <span class="lg:block">Service / Support</span>
						</div>
						<hr class="w-16 mx-auto border-t-8 border-siteRed">
						<div class="px-4 pt-2 leading-tight lg:px-10 xl:px-14 text-md lg:text-lg lg:leading-tight">
							Provides warranty for our products against factory defects.
						</div>
					</div>

				</div>
			</div>
		</div>

		<div class="bg-gray-700 section aboutus-section">
			<div class="grid lg:grid-cols-2">
				<div class="relative order-1 lg:order-2">
					<img class="right-0 object-cover lg:absolute lg:w-full lg:h-full" src="@/assets/images/aboutus-img01.jpg" alt="" width="860" height="573">
				</div>
				<div class="order-2 p-8 space-y-4 text-white lg:py-16 lg:space-y-12 lg:order-1">
					<div class="text-2xl font-bold tracking-widest uppercase lg:text-4xl font-rubik">
						Company Profile
					</div>
					<div class="space-y-4 lg:space-y-8 lg:text-lg">
						<p>Your one-stop-shop supplier, with over 30 years of experience in distribution of a wide range of construction, plumbing and fire protection supplies.</p>
						<p>We are one of the leading suppliers of multinational companies here in the Philippines.</p>
						<p>We guarantee to provide quality products for our valued customers nationwide.</p>
					</div>
				</div>
			</div>
		</div>
		
		<div id="contactus" class="section contactus-section bg-[#FFFFFFE8]">
			<div class="container py-8 space-y-4 lg:py-16 lg:space-y-12">
				<div class="text-2xl font-bold tracking-widest text-center uppercase lg:text-4xl font-rubik text-siteBlue-3">
					Contact Us
				</div>
				<div class="w-full space-y-12 lg:-mx-4 lg:space-y-0 lg:flex">
					<div class="w-full space-y-6 lg:px-4 lg:text-lg lg:w-5/12">
						<div class="flex w-full">
							<div class="w-3/12 pr-2 lg:w-2/12">
								<img class="ml-auto" src="@/assets/images/map-icon.png" alt="Map Icon" width="70" height="70">
							</div>
							<div class="w-9/12 pl-1 text-left lg:w-10/12">
								<p class="mb-2 text-lg font-bold lg:text-xl font-rubik text-siteRed">Address</p>
								<p class="leading-tight">
									12 6th St, Grace Park East,<br>
									Caloocan, 1006 Metro Manila
								</p>  
							</div>
						</div>
						<div class="flex w-full">
							<div class="w-3/12 pr-2 lg:w-2/12">
								<img class="ml-auto" src="@/assets/images/email-icon.png" alt="Email Icon" width="70" height="70">
							</div>
							<div class="w-9/12 pl-1 text-left lg:w-10/12">
								<p class="mb-2 text-lg font-bold lg:text-xl font-rubik text-siteRed">E-mail</p>
								<a href="mailto:aspenvalve@gmail.com" class="leading-tight hover:underline">
									aspenvalve@gmail.com
								</a>
							</div>
						</div>
						<div class="flex w-full">
							<div class="w-3/12 pr-2 lg:w-2/12">
								<img class="ml-auto" src="@/assets/images/tel-icon.png" alt="Tel Icon" width="70" height="70">
							</div>
							<div class="grid w-9/12 gap-4 pl-1 text-left lg:w-10/12 lg:grid-cols-2">
								<div class="">
									<p class="mb-2 text-lg font-bold lg:text-xl font-rubik text-siteRed">Telephone</p>
									<a href="tel:+63282444451" class="leading-tight hover:underline">
										(02) 8244 4451
									</a><br>
									<a href="tel:+63282441213" class="leading-tight hover:underline">
										(02) 8244 1213
									</a><br>
									<a href="tel:+63282441195" class="leading-tight hover:underline">
										(02) 8244 1195
									</a><br>
									<a href="tel:+63282442255" class="leading-tight hover:underline">
										(02) 8244 2255
									</a>
								</div>
								<div class="">
									<p class="mb-2 text-lg font-bold lg:text-xl font-rubik text-siteRed">Mobile</p>
									<a href="tel:+639176787168" class="leading-tight hover:underline">
										0917 678 7168
									</a><br>
									<a href="tel:+639176755168" class="leading-tight hover:underline">
										0917 675 5168
									</a><br>
									<a href="tel:+639178866384" class="leading-tight hover:underline">
										0917 886 6384
									</a>
								</div>
							</div>
						</div>
						<div class="flex w-full">
							<div class="w-3/12 pr-2 lg:w-2/12">
								<img class="ml-auto" src="@/assets/images/working-hrs-icon.png" alt="Work Hours Icon" width="70" height="70">
							</div>
							<div class="w-9/12">
								<p class="mb-2 text-lg font-bold lg:text-xl font-rubik text-siteRed">Working Hours:</p>
								<table class="">
									<tr>
										<td valign="top">Monday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">9 AM- 5:30PM</td>
									</tr>
									<tr>
										<td valign="top">Tuesday</td>
										<td valign="top" class="px-4">:</td>
										<td>9 AM - 5:30 PM</td>
									</tr>
									<tr>
										<td valign="top">Wednesday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">9 AM - 5:30 PM</td>
									</tr>
									<tr>
										<td valign="top">Thursday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">9 AM - 5:30 PM</td>
									</tr>
									<tr>
										<td valign="top">Friday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">9 AM- 5:30 PM</td>
									</tr>
									<tr>
										<td valign="top">Saturday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">9 AM - 4 PM</td>
									</tr>
									<tr>
										<td valign="top">Sunday</td>
										<td valign="top" class="px-4">:</td>
										<td valign="top">Closed</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
					<div class="w-full px-4 lg:w-7/12">
						<!-- feedback.activamedia.com.sg script begins here -->
						<!-- <iframe allowTransparency="true" class="form-height" style="height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5614647.html">
							<p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p>
						</iframe> -->
						<!-- feedback.activamedia.com.sg script ends here -->
						<ContactForm />
					</div>
				</div>
				<div class="text-center italic">
					<p>- Shipping within Metro Manila</p>
					<p>** Free delivery with Terms and Conditions applied</p>
				</div>
			</div>
		</div>

		<div class="bg-white section map-section">
      <iframe class="h-[300px] lg:h-[450px]" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15440.47046162029!2d120.9889966!3d14.6492643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ca0fc432f6c9%3A0x231a827081a3c17a!2sAspen%20Valves%20and%20Fittings%20Corp.!5e0!3m2!1sen!2sph!4v1725931350123!5m2!1sen!2sph" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index',
	components: {
		SiteHeader,
		SiteFooter,
		ContactForm,
	},
	data() {
		return {
			isOpen: 0,
			isTop: true,
		}
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 200;
		},
	},
}
</script>

<style>
	#mainpage {
		background-image: url('~@/assets/images/bg-pattern02.jpg');
		@apply bg-center bg-fixed;
	}
	.mainbanner-section {
		background-image: url('~@/assets/images/mainbanner-bg01.jpg');
		@apply bg-cover bg-center bg-no-repeat;
	}
	.bg-textured {
		background-image: url('~@/assets/images/bg-textured.jpg');
		@apply bg-cover bg-center bg-no-repeat;
	}

	.header-slider .owl-dots {
		/* @apply space-x-2 !my-8 !text-left; */
		@apply space-x-2 !-mt-8 lg:!-mt-16 relative;
		z-index: 2;
	}

	.header-slider .owl-dots .owl-dot span {
		@apply !bg-white !duration-200;
	}

	.text-shadow {
		text-shadow: 0 4px 5px #00000033;
	}

	.bullet-special li::before {
		content: "●";
		@apply text-siteBlue;
		font-weight: bold;
		display: inline-block;
		width: 1.2em;
		margin-left: -1.2em;
	}

	@screen sm {
		.product-columns {
			column-count: 2;
		}
	}
	@screen lg {
		.product-columns {
			column-count: 3;
		}
	}

	/* @media (min-height: 544px) {
		.min-h-screen {
			min-height: ;
		}
	} */

</style>