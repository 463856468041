<template>
	<footer id="site-footer" class="flex-shrink-0 mt-auto">
		<div class="bg-gray-700 section copyright-section">
			<div class="px-4 py-2 text-xs text-center text-white lg:py-4 lg:text-sm">
				Maintained and Developed by <a href="https://www.amph.com.ph/" target="_blank" class="inline-block hover:underline">AMPH Advertising Agency Inc.</a>
			</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>

<style>   
	/* .wa-float{
		position:fixed;
		width:60px;
		height:60px;
		bottom:40px;
		right:40px;
		background-color:#25d366;
		color:#FFF;
		border-radius:50px;
		text-align:center;
			font-size:30px;
		box-shadow: 2px 2px 3px #999;
			z-index:100;
	}
	.my-float{
		margin: 15px auto 0;
	} */
</style>
